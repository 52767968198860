import * as Sentry from "@sentry/node"
import { AppProps } from "next/app"
import { QueryClient, QueryClientProvider } from "react-query"
import { ChatProvider } from "@project/shared"
import "../utils/css-imports"
import "../styles.css"

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV !== "development",
    environment: `consumer-${process.env.NODE_ENV}`,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  })
}

const queryClient = new QueryClient()

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ChatProvider>
          <Component {...pageProps} />
        </ChatProvider>
      </QueryClientProvider>
    </>
  )
}
export default MyApp
